<template>
  <v-card
    :class="[
      'cy-details',
      'v-stepper',
      'new-header',
      classes,
      { 'sticky-footer__viewport': stickyFooter },
    ]">
    <slot name="default"/>

    <div
      v-if="$slots['footer']"
      :class="[
        'cy-details__actions',
        { 'sticky-footer__footer': stickyFooter },
      ]">
      <div class="cy-details__actions__wrapper">
        <slot name="footer"/>
      </div>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable vue/no-unused-properties */
import { VStepper } from 'vuetify/lib'

export default {
  name: 'CyStepper',
  extends: VStepper,
  props: {
    beforeStepChange: {
      type: Function,
      default: (current, target) => true,
    },
    stickyFooter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async stepClick (step) {
      const isValid = await this.beforeStepChange(this.internalValue, step)
      if (!isValid) return

      this.$nextTick(() => (this.internalValue = step))
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-stepper__header {
    border-bottom: solid 1px cy-get-color("primary", "light-5");
    box-shadow: none;
  }

  .v-stepper__items {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    flex-direction: row;
    overflow: hidden auto;

    > .v-stepper__content {
      display: flex;
      flex-direction: column;
      padding: 32px 56px;

      > .v-stepper__wrapper {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        overflow: visible;
      }
    }
  }

  .cy-details {
    &.new-header {
      &.v-card {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }

  .cy-details__actions__wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;

    > .v-stepper__content {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      padding: 0;
      transition: none;

      &[class*="tab-transition-leave"],
      &[class*="tab-reverse-transition-leave"] {
        display: none !important;
      }

      .v-btn.v-btn.cy-btn + .v-btn.v-btn.cy-btn {
        margin-left: 16px;
      }
    }
  }
}
</style>
