var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:[
    'cy-details',
    'v-stepper',
    'new-header',
    _vm.classes,
    { 'sticky-footer__viewport': _vm.stickyFooter } ]},[_vm._t("default"),(_vm.$slots['footer'])?_c('div',{class:[
      'cy-details__actions',
      { 'sticky-footer__footer': _vm.stickyFooter } ]},[_c('div',{staticClass:"cy-details__actions__wrapper"},[_vm._t("footer")],2)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }